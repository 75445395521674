export enum EnumStatus {
  WAITING_PAYMENT = 'waiting_payment',
  WAITING_PROCESSING = 'waiting_processing',
  PROCESSING = 'processing',
  PAID = 'paid',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export enum EnumBankPaymentStatus {
  INITIAL = 'initial',
  CREATED = 'created',
  SUBMITTED = 'submitted',
  CONFIRMED = 'confirmed',
  FAILED = 'failed',
}

export enum EnumMethod {
  PIX = 'pix',
  CASH = 'cash',
}

export enum EnumType {
  GIFT = 'gift',
  TRANSPORT = 'transport',
}

export enum EnumBank {
  STARK = 'stark',
}

export enum EnumAccountType {
  CHECKING = 'checking',
  SAVINGS = 'savings',
  SALARY = 'salary',
  PAYMENT = 'payment',
}
