import { $axios } from '@/plugins/axios'

export async function get(url: string, params: {} = {}): Promise<any> {
  const response = await $axios.get(url, { params })
  return response.data
}

export async function post(url: string, data: {} = {}): Promise<any> {
  const response = await $axios.post(url, data)
  return response.data
}

export async function postFormData(url: string, data: any): Promise<any> {
  const fd = new FormData()
  data = data || {}
  Object.keys(data).map((k: string) => {
    fd.append(k, data[k])
  })

  const response = await $axios.post(url, fd)
  return response.data
}
