import type { INotificationResponse } from '@/interfaces/notification'
import { get, post } from './ajaxutils'

export default {
  getNotifications(only_not_read: boolean, start: number, end: number): Promise<INotificationResponse> {
    return get('/api/notifications', { only_not_read, start, end })
  },
  read(notification_id: number | string): Promise<void> {
    return post('/api/notification/read', { notification_id })
  },
  unread(notification_id: number): Promise<void> {
    return post('/api/notification/unread', { notification_id })
  },
}
