<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn icon class="mx-3" v-bind="props">
        <v-avatar size="38">
          <v-img :src="gravatarImageUrl" alt="Avatar" />
        </v-avatar>
      </v-btn>
    </template>

    <v-card>
      <v-list lines="two">
        <v-list-item :prepend-avatar="gravatarImageUrl" :title="currentUser!.name" :subtitle="currentUser!.email" />
      </v-list>

      <v-divider />

      <v-list density="compact">
        <v-list-item>
          <v-switch
            density="compact"
            class="ml-2"
            v-model="isDarkMode"
            :label="$t('loggedMenu.darkMode')"
            @click="toggleTheme"
          />
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list density="compact">
        <v-list-item
          v-for="lang in languages"
          :key="lang.code"
          :value="lang.code"
          :title="lang.name"
          @click="changeLanguage(lang.code)"
        >
          <template v-slot:prepend>
            <i class="ma-0 pa-0 mr-2 flag" :class="`flag-${getFlag(lang.code)}`" />
          </template>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list class="clickable">
        <v-list-item :title="$t('loggedMenu.logout')" @click="logout" />
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import gravatar from 'gravatar'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTheme } from 'vuetify'
import api from '~api'

const theme = useTheme()
const { locale } = useI18n({ useScope: 'global' })

const auth = useAuthStore()
const { currentUser } = storeToRefs(auth)

const languages = computed(() => [
  { code: 'en', name: 'English' },
  { code: 'pt', name: 'Português' },
])
const gravatarImageUrl = computed(() => gravatar.url(currentUser!.value!.email, { d: 'mp' }))
const isDarkMode = computed(() => theme.global.current.value.dark)

function getFlag(language: string): string {
  if (language === 'en') return 'us'
  if (language === 'pt') return 'br'
  return ''
}

async function logout(): Promise<void> {
  try {
    await api.auth.logout()
    auth.removeCurrentUser()
  } catch {
    //
  }
}

async function changeLanguage(language: string): Promise<void> {
  try {
    await api.auth.changeLanguage(language)
    locale.value = language
    localStorage.setItem('language', language)
    window.location.reload()
  } catch {
    //
  }
}

function toggleTheme(): void {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
  localStorage.setItem('theme', theme.global.name.value)
}
</script>

<style lang="scss" scoped>
.v-switch {
  height: 40px;
}
</style>
