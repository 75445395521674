import type {
  IAccountManager,
  IAddress,
  IContact,
  ICustomer,
  ICustomerPaginated,
  IParamsFilter,
  ISpecificity,
} from '@/interfaces/customer'
import { get, post } from './ajaxutils'

export default {
  getCustomers(params: IParamsFilter): Promise<ICustomerPaginated> {
    return get('/api/customers', { params: JSON.stringify(params) })
  },
  saveCustomer(data: ICustomer): Promise<ICustomer> {
    return post('/api/customer/save', { ...data })
  },
  saveSequencial(id: number | null, sequencial: number, prefixo_orcamento: string): Promise<void> {
    return post('/api/customer/sequential/save', { id, sequencial, prefixo_orcamento })
  },
  saveAddress(data: IAddress, customer_id: number | null): Promise<Array<IAddress>> {
    return post('/api/customer/adress/save', { ...data, customer_id })
  },
  removeAddress(adress_id: number | null): Promise<Array<IAddress>> {
    return post('/api/customer/adress/remove', { adress_id })
  },
  saveContact(
    data: IContact,
    address_id: number | null
  ): Promise<{ contacts: Array<IContact>; addresses: Array<IAddress> }> {
    return post('/api/customer/address/contact/save', { ...data, address_id })
  },
  removeContact(contact_id: number | null): Promise<{ contacts: Array<IContact>; addresses: Array<IAddress> }> {
    return post('/api/customer/address/contact/remove', { contact_id })
  },
  saveSpecificity(data: ISpecificity, customer_id: number | null): Promise<Array<ISpecificity>> {
    return post('/api/customer/specificity/save', { ...data, customer_id })
  },
  removeSpecificity(specificity_id: number | null): Promise<Array<ISpecificity>> {
    return post('/api/customer/specificity/remove', { specificity_id })
  },
  getSpecificities(customer_id: number | null | undefined): Promise<Array<ISpecificity>> {
    return get('/api/customer/specificities', { customer_id })
  },
  addAccountManager(account_manager_id: number | null, customer_id: number | null): Promise<Array<IAccountManager>> {
    return post('/api/customer/account_manager/add', { account_manager_id, customer_id })
  },
  removeAccountManager(account_manager_id: number | null, customer_id: number | null): Promise<Array<IAccountManager>> {
    return post('/api/customer/account_manager/remove', { account_manager_id, customer_id })
  },
  getAccountManagers(customer_id: number | null | undefined): Promise<Array<IAccountManager>> {
    return get('/api/customer/account_managers', { customer_id })
  },
}
