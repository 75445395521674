import type { EnumReasonType } from '@/enums/recruitment'
import type { IInstitution } from '@/interfaces/finance'
import type {
  IAdditionalService,
  IAdverseEvent,
  IArea,
  IBillingStatus,
  ICosmeticForm,
  ICostCenter,
  ICountry,
  ICurrency,
  ICustomer,
  IFoodAssistance,
  IPaymentForm,
  IPaymentMethod,
  IPhototype,
  IProduct,
  IProfileDifficulty,
  IReason,
  IRole,
  IStatusEntryDocument,
  IStatusGroup,
  IStatusParticipantRecruitment,
  IStatusParticipantSchedule,
  IStatusPayment,
  IStatusProposal,
  IStatusStudyPlan,
  IStayLength,
  IStudyRegion,
  IStudyType,
  ITechnicalArea,
  IUnit,
} from '@/interfaces/select'
import { get } from './ajaxutils'

export default {
  getCustomers(): Promise<Array<ICustomer>> {
    return get('/api/select/commercial/customers')
  },
  getProducts(): Promise<Array<IProduct>> {
    return get('/api/select/commercial/products')
  },
  getAreas(): Promise<Array<IArea>> {
    return get('/api/select/commercial/areas')
  },
  getTechnicalAreas(): Promise<Array<ITechnicalArea>> {
    return get('/api/select/commercial/technical_areas')
  },
  getCurrencies(): Promise<Array<ICurrency>> {
    return get('/api/select/commercial/currencies')
  },
  getStatus(): Promise<Array<IStatusProposal>> {
    return get('/api/select/commercial/status')
  },
  getBillingStatus(): Promise<Array<IBillingStatus>> {
    return get('/api/select/commercial/billing_status')
  },
  getAdditionalServices(): Promise<Array<IAdditionalService>> {
    return get('/api/select/commercial/additional_services')
  },
  getStatusEntryDocument(): Promise<Array<IStatusEntryDocument>> {
    return get('/api/select/commercial/entry_document/status')
  },
  getStatusStudyPlan(): Promise<Array<IStatusStudyPlan>> {
    return get('/api/select/commercial/study_plan/status')
  },
  getCosmeticForms(): Promise<Array<ICosmeticForm>> {
    return get('/api/select/commercial/study_plan/patch_test/cosmetic_forms')
  },
  getCountries(): Promise<Array<ICountry>> {
    return get('/api/select/countries')
  },
  getRoles(): Promise<Array<IRole>> {
    return get('/api/select/roles')
  },
  getCostCenters(): Promise<Array<ICostCenter>> {
    return get('/api/select/commercial/cost_centers')
  },
  getPhototypes(): Promise<Array<IPhototype>> {
    return get('/api/select/recruitment/phototypes')
  },
  getStudyRegions(): Promise<Array<IStudyRegion>> {
    return get('/api/select/recruitment/study_regions')
  },
  getStudyTypes(): Promise<Array<IStudyType>> {
    return get('/api/select/recruitment/study_types')
  },
  getUnits(): Promise<Array<IUnit>> {
    return get('/api/select/recruitment/units')
  },
  getStayLengths(recruitment_id: number | null): Promise<Array<IStayLength>> {
    return get('/api/select/recruitment/stay_lengths', { recruitment_id })
  },
  getPaymentMethods(): Promise<Array<IPaymentMethod>> {
    return get('/api/select/recruitment/payment_methods')
  },
  getFoods(): Promise<Array<IFoodAssistance>> {
    return get('/api/select/recruitment/foods')
  },
  getDifficulties(): Promise<Array<IProfileDifficulty>> {
    return get('/api/select/recruitment/profile_difficulties')
  },
  getStatusGroup(): Promise<Array<IStatusGroup>> {
    return get('/api/select/recruitment/group/status')
  },
  getStatusParticipantSchedule(): Promise<Array<IStatusParticipantSchedule>> {
    return get('/api/select/recruitment/schedule/status')
  },
  getStatusParticipantRecruitment(): Promise<Array<IStatusParticipantRecruitment>> {
    return get('/api/select/recruitment/participant/status')
  },
  getReasons(reason_type: EnumReasonType | string): Promise<Array<IReason>> {
    return get('/api/select/recruitment/reasons', { reason_type })
  },
  getStatusPayment(): Promise<Array<IStatusPayment>> {
    return get('/api/select/finance/payment/status')
  },
  getAdverseEvents(): Promise<Array<IAdverseEvent>> {
    return get('/api/select/finance/adverse_events')
  },
  getInstitutions(): Promise<Array<IInstitution>> {
    return get('/api/select/finance/institutions')
  },
  getPaymentForms(): Promise<Array<IPaymentForm>> {
    return get('/api/select/commercial/payment_forms')
  },
}
