import cpfCnpjHelper from '@/helpers/cpf-cnpj-helper'
import formHelpers from '@/helpers/form'
import filters from '@/plugins/filters'
import i18n from '@/plugins/i18n'

const { t } = i18n.global

const rules = {
  required(value: string | File[]): string | boolean {
    let valid = false
    if (!value) valid = false
    else if (typeof value === 'string') valid = !!value.replace(/\s/g, '')
    else if (typeof value === 'object') valid = !!Object.keys(value).length
    else valid = !!value
    return valid || t('rules.required')
  },
  minlen(m: number) {
    return (v: string): string | boolean => {
      if (v === undefined || v === null || v === '') return true
      v = v.toString()
      return v.length >= m || t('rules.minlen', { m })
    }
  },
  maxlen(m: number) {
    return (v: string): string | boolean => {
      if (v === undefined || v === null || v === '') return true
      v = v.toString()
      return v.length <= m || t('rules.maxlen', { m })
    }
  },
  email(value: string): string | boolean {
    if (value === undefined || value === null) return true
    const valid = formHelpers.isValidEmail(value.trim())
    return valid || t('rules.email')
  },
  phone(value: string): string | boolean {
    if (!value) return true
    const valid = value.length >= 10 && formHelpers.isValidPhone(value)
    return valid || t('rules.phone')
  },
  cpf(value: string): string | boolean {
    if (value === undefined || value === null) return true
    return cpfCnpjHelper.isValidCpf(value) || t('rules.cpf')
  },
  cnpj(value: string): string | boolean {
    if (value === undefined || value === null) return true
    return cpfCnpjHelper.isValidCnpj(value) || t('rules.cnpj')
  },
  cpf_or_cnpj(value: string): string | boolean {
    if (value === undefined || value === null) return true
    return cpfCnpjHelper.isValidCnpj(value) || cpfCnpjHelper.isValidCpf(value) || t('rules.cpf_or_cnpj')
  },
  password(value: string): string | boolean {
    return formHelpers.isValidPassword(value) || t('rules.password')
  },
  passwordConfirmation: (password: string, passwordConfirmation: string): string | boolean => {
    return password === passwordConfirmation || t('rules.passwordConfirmation')
  },
  between(value: string | number, start: number, end: number, currency: boolean = false): string | boolean {
    let message = t('rules.between', { start, end })
    if (currency) {
      message = t('rules.between', { start: filters.currency(start, 'R$'), end: filters.currency(end, 'R$') })
    }
    if (value === undefined || value === null) return message
    value = value.toString()
    if (formHelpers.isValidCurrency(value)) {
      value = formHelpers.moedaToAmount(value)
    } else {
      value = value.replace(',', '.')
    }
    return ((value as number) >= start && (value as number) <= end) || message
  },
  greaterThan(value: number | string, vmin: number, currency: boolean = false): string | boolean {
    let message = t('rules.greaterThan', { vmin })
    if (currency) {
      message = t('rules.greaterThan', { vmin: filters.currency(vmin, 'R$') })
    }
    if (value === undefined || value === null) return message
    value = value.toString()
    if (formHelpers.isValidCurrency(value)) {
      value = formHelpers.moedaToAmount(value)
    } else {
      value = value.replace(',', '.')
    }
    return (value as number) > vmin || message
  },
  alpha(value: string): string | boolean {
    if (value === undefined || value === null) return true
    return value.match(/^([^0-9]*)$/) !== null || t('rules.alpha')
  },
  alphanumeric(value: string): string | boolean {
    if (value === undefined || value === null) return true
    return value.match(/^[a-z0-9]+$/i) !== null || t('rules.alphanumeric')
  },
  number(value: string): string | boolean {
    if (value === undefined || value === null || value === '') return true
    return String(value).match(/^[0-9]+$/i) !== null || t('rules.number')
  },
  exactLength(len: number) {
    return (value: string): string | boolean => {
      if (value === undefined || value === null) return true
      value = value.toString()
      return value.length === len || t('rules.exactLength', { len })
    }
  },
  cep(value: string): string | boolean {
    if (value === undefined || value === null) return true
    return (value.match(/^[0-9]+$/i) !== null && value.length === 8) || t('rules.cep')
  },
  matchText(value: string, pattern: string, exact: boolean = false): string | boolean {
    if (value === undefined || value === null) return true
    const error = t('rules.matchText', { pattern })
    return exact ? value === pattern || error : value.toLowerCase() === pattern || error
  },
  date(value: string): string | boolean {
    if (!value) return true
    return /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/.test(value) || t('rules.date')
  },
  time(value: string): string | boolean {
    if (!value) return true
    return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value) || t('rules.time')
  },
  not_allowed(value: string, not_allowed_word: string = ''): string | boolean {
    if (!value || !not_allowed_word) return true
    return !value.toLowerCase().includes(not_allowed_word.toLowerCase()) || t('rules.notAllowed', { not_allowed_word })
  },
  pix_key_valid(value: string): string | boolean {
    if (!value) return true
    return (
      formHelpers.isValidEmail(value.trim()) ||
      cpfCnpjHelper.isValidCpf(value) ||
      cpfCnpjHelper.isValidCnpj(value) ||
      value.length === 36 ||
      value.startsWith('+55') ||
      t('rules.invalidPixKey')
    )
  },
}

// @ts-expect-error
rules.mergeRules = (otherRules: {}): {} => {
  return Object.assign({}, rules, otherRules)
}

export default rules
