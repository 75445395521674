import { EnumMethod, EnumType } from '@/enums/finance'
import { EnumFileType } from '@/enums/recruitment'
import cpfCnpjHelper from '@/helpers/cpf-cnpj-helper'
import i18n from '@/plugins/i18n'
import dayjs from 'dayjs'

const { t } = i18n.global

export function parse_date(value: string): dayjs.Dayjs {
  let date = dayjs(value)

  const date_splited = value.split('T')
  if (date_splited.length > 1 && date_splited[1] === '00:00:00+00:00') {
    date = date.add(3, 'hour')
  }

  return date
}

const filters = {
  cpf_or_cnpj(value: string | undefined): string {
    if (!value) return ''
    return cpfCnpjHelper.format(value)
  },
  currency(amount: string | number, prefix: string = 'R$', signed: boolean = false, suffix: string = ''): string {
    if (amount === undefined || amount === null) return ''
    const value = parseInt(amount as string) / 100.0
    const signal = value < 0 ? '- ' : signed && Math.abs(value) >= 0.01 ? '+ ' : ''
    const values = Number(Math.abs(value)).toFixed(2).split('.')
    const currencyValue = [values[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'), values[1]].filter(Boolean).join()
    if (suffix) return `${signal} ${currencyValue} ${suffix}`
    return `${signal}${prefix} ${currencyValue}`
  },
  time(value: string | undefined): string {
    if (!value) return ''
    return parse_date(value).format('HH:mm')
  },
  shortdaymonthhour(value: string | undefined): string {
    if (!value) return ''
    return parse_date(value).format('DD/MM/YYYY HH:mm')
  },
  brdate(value: string): string {
    if (!value) return ''
    return parse_date(value).format('DD/MM/YYYY')
  },
  brdatemonth(value: string): string {
    if (!value) return ''
    return parse_date(value).format('MM/YYYY')
  },
  phone(value: string): string {
    if (!value) return ''
    return value.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
  },
  zipcode(value: string): string {
    if (!value) return ''
    return value.replace(/(\d{5})(\d{3})/, '$1-$2')
  },
  history(value: string, field: string): string {
    if (value === 'pt') return t('filters.history.portuguese')
    if (value === 'en') return t('filters.history.english')
    if (value === 'sim' || value === 'True') return t('filters.history.yes')
    if (value === 'nao' || value === 'False') return t('filters.history.no')
    if (value === 'naoaplica') return 'N/A'
    if (['discount_percentage', 'tax_percentage'].includes(field)) return this.currency(value, undefined, false, '%')
    if (
      [
        'discount_value',
        'preco_real',
        'unit_value',
        'differentiated_gift_value',
        'amount',
        'transport_same_city_value',
        'transport_different_city_value',
      ].includes(field)
    )
      return this.currency(value, 'R$')
    if (field === 'preco_dolar') return this.currency(value, 'US$')
    if (field === 'preco_euro') return this.currency(value, '€')
    return value || ''
  },
  method(value: string): string {
    if (value === EnumMethod.CASH) return t('general.finance.cash')
    if (value === EnumMethod.PIX) return t('general.finance.transfer')
    return value || ''
  },
  type(value: string): string {
    if (value === EnumType.GIFT) return t('general.finance.gift')
    if (value === EnumType.TRANSPORT) return t('general.finance.transport')
    return value || ''
  },
  fileType(value: string): string {
    if (value === EnumFileType.PROFILE) return t('general.recruitmentFile.profile')
    if (value === EnumFileType.DIFFERENTIATED_GIFT) return t('general.recruitmentFile.differentiated_gift')
    if (value === EnumFileType.TRANSPORT) return t('general.recruitmentFile.transport')
    return value || ''
  },
}

export default filters
