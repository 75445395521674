import App from '@/App.vue'
import rules from '@/helpers/rules'
import axios from '@/plugins/axios'
import filters from '@/plugins/filters'
import i18n from '@/plugins/i18n'
import router from '@/plugins/router'
import { initSentry } from '@/plugins/sentry'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import '@/assets/css/main.scss'

declare module 'vue' {
  interface ComponentCustomProperties {
    $filters: typeof filters
    $rules: typeof rules
  }
}

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(axios)
app.use(vuetify)
app.use(i18n)

loadFonts()

initSentry(app)

app.config.globalProperties.$filters = filters
app.config.globalProperties.$rules = rules
app.config.globalProperties.$metabaseUrl = import.meta.env.VITE_METABASE_BASE_URL

app.mount('#app')
