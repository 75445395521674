import { defineStore } from 'pinia'
import api from '~api'

import type { IArea, ICurrency, ICustomer, IPaymentForm, IProduct, ITechnicalArea } from '@/interfaces/select'
interface ISelect {
  customers: Array<ICustomer>
  products: Array<IProduct>
  areas: Array<IArea>
  technicalAreas: Array<ITechnicalArea>
  currencies: Array<ICurrency>
  paymentForms: Array<IPaymentForm>
}

export const useSelectStore = defineStore('select', {
  state: () =>
    ({
      customers: [],
      products: [],
      areas: [],
      technicalAreas: [],
      currencies: [],
      paymentForms: [],
    }) as ISelect,
  actions: {
    async setCustomers(): Promise<void> {
      this.customers = await api.select.getCustomers()
    },
    async setProducts(): Promise<void> {
      this.products = await api.select.getProducts()
    },
    async setAreas(): Promise<void> {
      this.areas = await api.select.getAreas()
    },
    async setTechnicalAreas(): Promise<void> {
      this.technicalAreas = await api.select.getTechnicalAreas()
    },
    async setCurrencies(): Promise<void> {
      this.currencies = await api.select.getCurrencies()
    },
    async setPaymentForms(): Promise<void> {
      this.paymentForms = await api.select.getPaymentForms()
    },
  },
})
