import type { IActionToken, IParamsFilter, IRegister, IUser, IUserPaginated, IWhoAmI } from '@/interfaces/user'
import { get, post, postFormData } from './ajaxutils'

export default {
  getUsers(params: IParamsFilter): Promise<IUserPaginated> {
    return get('/api/users', { params: JSON.stringify(params) })
  },
  getUsersAll(): Promise<Array<IUser>> {
    return get('/api/users/all')
  },
  getUsersAccountManagers(): Promise<Array<IUser>> {
    return get('/api/users/account_managers')
  },
  login(email: string, password: string): Promise<IUser> {
    return post('/api/login', { email, password })
  },
  logout(): Promise<void> {
    return post('/api/logout')
  },
  whoami(): Promise<IWhoAmI> {
    return get('/api/whoami')
  },
  getActionToken(code: string, type: string): Promise<IActionToken> {
    return get('/api/action_token', { code, type })
  },
  forgotPassword(email: string): Promise<void> {
    return post('/api/forgot_password', { email })
  },
  resetPassword(code: string, password: string): Promise<IUser> {
    return post('/api/reset_password', { code, password })
  },
  sendInvites(emails: Array<string>): Promise<void> {
    return post('/api/invite', { emails })
  },
  register(code: string, data: IRegister): Promise<IUser> {
    return post('/api/register', { code, data })
  },
  saveUser(user_id: number | null, role_id: number | null, signature: File | undefined): Promise<void> {
    return postFormData('/api/user/save', {
      user_id,
      role_id,
      signature,
    })
  },
  changeLanguage(language: string): Promise<void> {
    return post('/api/language', { language })
  },
}
