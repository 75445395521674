import router from '@/plugins/router'
import { useAuthStore } from '@/stores/auth'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'

export function initSentry(app: App): void {
  const sentry_dsn = import.meta.env.VITE_SENTRY_DSN || ''
  if (!sentry_dsn) {
    Sentry.init({ dsn: '' })
    console.log('sentry/browser disabled') // eslint-disable-line no-console
    return
  }

  const sentryOptions = {
    app,
    dsn: sentry_dsn,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: +(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0),
    tracePropagationTargets: ['localhost', 'app.kosmoscience.net', 'testapp.kosmoscience.net', /^\//],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    release: 'MAIN',
    ignoreErrors: [
      /Failed to fetch dynamically imported module/,
      /error loading dynamically imported module/,
      /ResizeObserver/,
      /Unable to preload CSS for/,
    ],
    beforeSend(event: any): any {
      event.user = getUser()
      return event
    },
  }

  Sentry.init(sentryOptions)
}

function getUser(): {} {
  const { currentUser } = useAuthStore()

  if (!currentUser?.id) return {}

  const { id, name, email, language } = currentUser
  return { id, name, email, language }
}
