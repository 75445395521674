import handler from '@/helpers/ajax-handler'
import axios from 'axios'
import type { App } from 'vue'

const $axios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || '/',
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
  withXSRFToken: true,
  withCredentials: true,
})

$axios.interceptors.response.use(handler.onPromiseResolved, (error: any) => handler.onPromiseRejected(error))

export { $axios }

export default {
  install: (app: App): void => {
    app.config.globalProperties.$axios = $axios
  },
}
