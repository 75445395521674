import type {
  IAdditionalService,
  IBillingFile,
  ICancelResponse,
  IFollowUp,
  IParamsFilter,
  IPopStudy,
  IProduct,
  IProposal,
  IProposalFile,
  IProposalPaginated,
  IRemoveSampleResponse,
  ISample,
  ISaveProposalStepOne,
  ISaveProposalStepSix,
  ISaveProposalStepTwo,
  IVisualDescription,
} from '@/interfaces/proposal'
import type { ITransition } from '@/interfaces/transition'
import type { IUser } from '@/interfaces/user'
import { get, post, postFormData } from './ajaxutils'

export default {
  getProposals(params: IParamsFilter): Promise<IProposalPaginated> {
    return get('/api/proposals', { params: JSON.stringify(params) })
  },
  getProposalIssuers(): Promise<Array<IUser>> {
    return get('/api/proposal/issuers')
  },
  saveProposalStepOne(data: ISaveProposalStepOne): Promise<IProposal> {
    return post('/api/proposal/step_one/save', { ...data })
  },
  saveProposalStepTwo(data: ISaveProposalStepTwo): Promise<IProposal> {
    return post('/api/proposal/step_two/save', { ...data })
  },
  saveProposalStepSix(data: ISaveProposalStepSix): Promise<IProposal> {
    return post('/api/proposal/step_six/save', { ...data })
  },
  saveSample(sample: ISample): Promise<Array<ISample>> {
    return post('/api/proposal/sample/edit', { ...sample })
  },
  addSample(
    description: string,
    rinsable: boolean,
    not_rinsable: boolean,
    proposal_id: number | null
  ): Promise<Array<ISample>> {
    return post('/api/proposal/sample/save', { description, rinsable, not_rinsable, proposal_id })
  },
  removeSample(sample_id: number | null): Promise<IRemoveSampleResponse> {
    return post('/api/proposal/sample/delete', { sample_id })
  },
  saveProposalProduct(product: IProduct): Promise<Array<IProduct>> {
    return post('/api/proposal/product/edit', { ...product })
  },
  addProposalProduct(product_id: number | null, orcamento_id: number | null): Promise<Array<IProduct>> {
    return post('/api/proposal/product/save', { product_id, orcamento_id })
  },
  removeProposalProduct(orcamento_product_id: number | null): Promise<Array<IProduct>> {
    return post('/api/proposal/product/delete', { orcamento_product_id })
  },
  saveAdditionalService(additional_service: IAdditionalService): Promise<Array<IAdditionalService>> {
    return post('/api/proposal/additional_service/edit', { ...additional_service })
  },
  addAdditionalService(
    additional_service_id: number | null | undefined,
    quantity: number,
    value: number,
    orcamento_id: number | null
  ): Promise<Array<IAdditionalService>> {
    return post('/api/proposal/additional_service/save', { additional_service_id, quantity, value, orcamento_id })
  },
  removeAdditionalService(quote_additional_service_id: number | null): Promise<Array<IAdditionalService>> {
    return post('/api/proposal/additional_service/delete', { quote_additional_service_id })
  },
  getTransitions(orcamento_id: number | null, tipo: string): Promise<Array<ITransition>> {
    return get('/api/proposal/transitions', { orcamento_id, tipo })
  },
  sendToCustomer(orcamento_id: number | null): Promise<void> {
    return post('/api/proposal/send_to_customer', { orcamento_id })
  },
  approve(orcamento_id: number | null): Promise<void> {
    return post('/api/proposal/approve', { orcamento_id })
  },
  sendToTechnicalArea(orcamento_id: number | null, users: string): Promise<void> {
    return post('/api/proposal/send_to_technical_area', { orcamento_id, users })
  },
  startReissue(orcamento_id: number | null): Promise<void> {
    return post('/api/proposal/start_reissue', { orcamento_id })
  },
  finishReissue(orcamento_id: number | null): Promise<void> {
    return post('/api/proposal/finish_reissue', { orcamento_id })
  },
  finish(orcamento_id: number | null): Promise<void> {
    return post('/api/proposal/finish', { orcamento_id })
  },
  disapprove(orcamento_id: number | null): Promise<void> {
    return post('/api/proposal/disapprove', { orcamento_id })
  },
  cancel(orcamento_id: number | null, clone: boolean): Promise<ICancelResponse> {
    return post('/api/proposal/cancel', { orcamento_id, clone })
  },
  bill(proposal_id: number | null): Promise<void> {
    return post('/api/proposal/send_for_billing', { proposal_id })
  },
  generatePdf(quote_id: number | null): Promise<string> {
    return post('/api/proposal/pdf', { quote_id })
  },
  addSampleProduct(quote_product_id: number | null, sample_id: string): Promise<Array<ISample>> {
    return post('/api/proposal/sample_product/save', {
      quote_product_id,
      sample_id,
    })
  },
  removeSampleProduct(quote_product_id: number | null, sample_product_id: number | null): Promise<Array<ISample>> {
    return post('/api/proposal/sample_product/delete', { quote_product_id, sample_product_id })
  },
  addVisualDescription(
    quote_product_id: number | null,
    visual_description: File,
    caption: string
  ): Promise<Array<IVisualDescription>> {
    return postFormData('/api/proposal/visual_description/save', {
      quote_product_id,
      visual_description,
      caption,
    })
  },
  removeVisualDescription(visual_description_id: number | null): Promise<Array<IVisualDescription>> {
    return post('/api/proposal/visual_description/delete', { visual_description_id })
  },
  generateNewRevision(quote_id: number | null): Promise<void> {
    return post('/api/proposal/revision', { quote_id })
  },
  generateBillingRevision(proposal_id: number | null): Promise<void> {
    return post('/api/proposal/billing/revision', { proposal_id })
  },
  createReissue(customer_id: number | null | undefined): Promise<void> {
    return post('/api/proposal/reissue', { customer_id })
  },
  addProposalFile(proposal_id: number | null, file: File): Promise<Array<IProposalFile>> {
    return postFormData('/api/proposal/file/save', {
      proposal_id,
      file,
    })
  },
  removeProposalFile(proposal_file_id: number | null): Promise<Array<IProposalFile>> {
    return post('/api/proposal/file/delete', { proposal_file_id })
  },
  addBillingFile(proposal_id: number | null, file: File): Promise<Array<IBillingFile>> {
    return postFormData('/api/proposal/billing/file/save', {
      proposal_id,
      file,
    })
  },
  removeBillingFile(billing_file_id: number | null): Promise<Array<IBillingFile>> {
    return post('/api/proposal/billing/file/delete', { billing_file_id })
  },
  getPopStudies(proposal_id: number | null): Promise<Array<IPopStudy>> {
    return get('/api/proposal/studies', { proposal_id })
  },
  getFollowUps(proposal_id: number | null): Promise<Array<IFollowUp>> {
    return get('/api/proposal/follow_ups', { proposal_id })
  },
  saveFollowUp(proposal_id: number | null, data: IFollowUp): Promise<void> {
    return post('/api/proposal/follow_up/save', { proposal_id, ...data })
  },
  removeFollowUp(follow_up_id: number | null): Promise<void> {
    return post('/api/proposal/follow_up/delete', { follow_up_id })
  },
}
