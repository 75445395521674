import type { IHistory } from '@/interfaces/history'
import type { IGroup, IGroupPaginated, IParamsFilterGroup, IResponsible } from '@/interfaces/recruitment'
import { get, post } from './ajaxutils'

export default {
  getGroups(params: IParamsFilterGroup): Promise<IGroupPaginated> {
    return get('/api/recruitment/groups', { params: JSON.stringify(params) })
  },
  getGroup(group_id: number): Promise<IGroup> {
    return get('/api/recruitment/group', { group_id })
  },
  getHistory(group_id: number): Promise<Array<IHistory>> {
    return get('/api/recruitment/group/history', { group_id })
  },
  getResponsibles(group_id: number | null): Promise<Array<IResponsible>> {
    return get('/api/recruitment/group/responsibles', { group_id })
  },
  assignGroupResponsible(group_id: number | null, responsible_id: number | null): Promise<void> {
    return post('/api/recruitment/group/responsible/add', { group_id, responsible_id })
  },
  unassignGroupResponsible(group_id: number | null, responsible_id: number | null): Promise<void> {
    return post('/api/recruitment/group/responsible/delete', { group_id, responsible_id })
  },
  cancel(group_id: number | null): Promise<void> {
    return post('/api/recruitment/group/cancel', { group_id })
  },
}
