<template>
  <v-app>
    <Toolbar v-if="loggedIn && !options.hideToolbar" />

    <Toast />

    <ErrorDialog />

    <v-main :class="{ 'login-background': !loggedIn }">
      <RouterView />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import Toolbar from '@/components/Toolbar.vue'
import ErrorDialog from '@/components/utils/ErrorDialog.vue'
import Toast from '@/components/utils/Toast.vue'
import { useAuthStore } from '@/stores/auth'
import { useSelectStore } from '@/stores/select'
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const { loggedIn } = storeToRefs(useAuthStore())

const options = computed(() => route.meta)

function loadStore(): void {
  const select = useSelectStore()

  select.setCustomers()
  select.setAreas()
  select.setTechnicalAreas()
}

watch(loggedIn, value => {
  if (!value) {
    router.push({ name: 'login' })
    return
  }

  loadStore()
})
</script>

<style lang="scss" scoped>
.login-background {
  background-image: url('@/assets/images/login-background.jpg');
  background-size: cover;
  background-position: center center;
}
</style>
