import { defineStore } from 'pinia'

interface IErrorDialog {
  opened?: boolean
  title: string
  description: string
  type?: string
}

export const useErrorDialogStore = defineStore('errorDialog', {
  state: () =>
    ({
      opened: false,
      title: '',
      description: '',
      type: '',
    }) as IErrorDialog,
  actions: {
    open(data: IErrorDialog): void {
      this.opened = true
      this.title = data.title
      this.description = data.description
      this.type = data.type
    },
    close(): void {
      this.opened = false
      this.title = ''
      this.description = ''
      this.type = ''
    },
  },
})
