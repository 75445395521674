import type { IHistory, IHistoryPdf } from '@/interfaces/history'
import type { ISaveHistory } from '@/interfaces/proposal'
import { get, post } from './ajaxutils'

export default {
  getHistory(quote_id: number): Promise<Array<IHistory>> {
    return get('/api/proposal/history', { quote_id })
  },
  saveQuoteHistory(data: ISaveHistory): Promise<void> {
    return post('/api/proposal/history/save', { ...data })
  },
  getHistoryPdf(quote_id: number | null): Promise<Array<IHistoryPdf>> {
    return get('/api/proposal/history/pdf', { quote_id })
  },
}
