import i18n from '@/plugins/i18n'
import { useAuthStore } from '@/stores/auth'
import { useErrorDialogStore } from '@/stores/error-dialog'
const { t } = i18n.global

export default {
  onPromiseResolved(response: any): any {
    return response
  },
  onPromiseRejected(error: any): any {
    const isNetworkError = !!error.isAxiosError && !error.response
    const { status, data } = error.response || {}

    const errorDialog = useErrorDialogStore()
    const auth = useAuthStore()

    if (isNetworkError) {
      errorDialog.open({
        title: t('ajax.network_error'),
        description: t('ajax.network_error_description'),
      })
      return error
    }

    switch (status) {
      case 400:
        errorDialog.open({
          title: t('ajax.error'),
          description: data,
        })
        throw error

      case 401:
        errorDialog.open({
          title: t('ajax.error'),
          description: t('ajax.expired_session'),
        })
        auth.removeCurrentUser()
        throw error

      case 403:
        errorDialog.open({
          title: t('ajax.error'),
          description: t('ajax.forbidden'),
        })
        throw error

      case 404:
        throw error

      default:
        errorDialog.open({
          title: t('ajax.unknown_error'),
          description: t('ajax.unknown_error_description'),
        })
        throw error
    }
  },
}
