import type { EnumFileType } from '@/enums/recruitment'
import type { IHistory } from '@/interfaces/history'
import type {
  IAddGroup,
  IAddSample,
  IAddStudyPlan,
  IAdditionalVisit,
  IGroupRecruitment,
  IParamsFilterRecruitment,
  IRecruitment,
  IRecruitmentPaginated,
  IRemoveStudyPlanResponse,
  IResponsible,
  ISample,
  ISaveRecruitmentStepFive,
  ISaveRecruitmentStepOne,
  ISaveRecruitmentStepTwo,
  IStudyPlan,
} from '@/interfaces/recruitment'
import { get, post, postFormData } from './ajaxutils'

export default {
  getRecruitments(params: IParamsFilterRecruitment): Promise<IRecruitmentPaginated> {
    return get('/api/recruitments', { params: JSON.stringify(params) })
  },
  getHistory(recruitment_id: number): Promise<Array<IHistory>> {
    return get('/api/recruitment/history', { recruitment_id })
  },
  getStudyPlans(recruitment_id: number | null): Promise<Array<IStudyPlan>> {
    return get('/api/recruitment/study_plans', { recruitment_id })
  },
  addStudyPlan(data: IAddStudyPlan): Promise<Array<IStudyPlan>> {
    return post('/api/recruitment/study_plan/add', { ...data })
  },
  removeStudyPlan(recruitment_id: number | null, study_plan_id: number | null): Promise<IRemoveStudyPlanResponse> {
    return post('/api/recruitment/study_plan/delete', { recruitment_id, study_plan_id })
  },
  getSamples(recruitment_id: number | null): Promise<Array<ISample>> {
    return get('/api/recruitment/samples', { recruitment_id })
  },
  addSample(data: IAddSample): Promise<Array<ISample>> {
    return post('/api/recruitment/sample/add', { ...data })
  },
  removeSample(recruitment_id: number | null, sample_id: number | null): Promise<Array<ISample>> {
    return post('/api/recruitment/sample/delete', { recruitment_id, sample_id })
  },
  addGroup(data: IAddGroup): Promise<Array<IGroupRecruitment>> {
    return post('/api/recruitment/group/add', { ...data })
  },
  removeGroup(group_id: number | null): Promise<Array<IGroupRecruitment>> {
    return post('/api/recruitment/group/delete', { group_id })
  },
  saveRecruitmentStepOne(data: ISaveRecruitmentStepOne): Promise<IRecruitment> {
    return post('/api/recruitment/step_one/save', { ...data })
  },
  saveRecruitmentStepTwo(data: ISaveRecruitmentStepTwo): Promise<IRecruitment> {
    return post('/api/recruitment/step_two/save', { ...data })
  },
  saveRecruitmentStepFive(data: ISaveRecruitmentStepFive): Promise<IRecruitment> {
    return post('/api/recruitment/step_five/save', { ...data })
  },
  getResponsibles(): Promise<Array<IResponsible>> {
    return get('/api/recruitment/responsibles')
  },
  notifyRecruitment(recruitment_id: number | null): Promise<void> {
    return post('/api/recruitment/notify', { recruitment_id })
  },
  addFile(recruitment_id: number | null, file: File, type: EnumFileType | null): Promise<IRecruitment> {
    return postFormData('/api/recruitment/file/save', {
      recruitment_id,
      file,
      type,
    })
  },
  removeFile(recruitment_file_id: number | null): Promise<IRecruitment> {
    return post('/api/recruitment/file/delete', { recruitment_file_id })
  },
  getAdditionalVisits(schedule_id: number | null): Promise<Array<IAdditionalVisit>> {
    return get('/api/recruitment/participant/additional_visits', { schedule_id })
  },
  saveAdditionalVisit(schedule_id: number | null, data: IAdditionalVisit): Promise<void> {
    return post('/api/recruitment/participant/additional_visit/save', { schedule_id, ...data })
  },
  removeAdditionalVisit(schedule_id: number | null): Promise<void> {
    return post('/api/recruitment/participant/additional_visit/delete', { schedule_id })
  },
}
