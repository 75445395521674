import { format as formatCnpj, isValid as isValidCnpj } from '@fnando/cnpj'
import { format as formatCpf, isValid as isValidCpf } from '@fnando/cpf'

export default {
  isValidCpf(value: string): boolean {
    if (!value) return false
    return isValidCpf(value)
  },
  isValidCnpj(value: string): boolean {
    if (!value) return false
    return isValidCnpj(value)
  },
  format(value: string): string {
    if (!value) return ''
    if (value.length === 11) return formatCpf(value)
    if (value.length === 14) return formatCnpj(value)
    return value
  },
}
