import type { IHistory } from '@/interfaces/history'
import type { IGroup, ISaveSchedule, ISaveVisit } from '@/interfaces/recruitment'
import { get, post } from './ajaxutils'

export default {
  saveVisit(data: ISaveVisit): Promise<IGroup> {
    return post('/api/recruitment/visit/save', { ...data })
  },
  saveSchedule(data: ISaveSchedule): Promise<IGroup> {
    return post('/api/recruitment/schedule/save', { ...data })
  },
  removeSchedule(schedule_id: number | null): Promise<IGroup> {
    return post('/api/recruitment/schedule/delete', { schedule_id })
  },
  getHistory(schedule_id: number): Promise<Array<IHistory>> {
    return get('/api/recruitment/schedule/history', { schedule_id })
  },
  confirm(schedule_id: number | null, participant_recruitment_id: number | null): Promise<void> {
    return post('/api/recruitment/schedule/confirm', { schedule_id, participant_recruitment_id })
  },
  notConfirm(schedule_id: number | null, participant_recruitment_id: number | null): Promise<void> {
    return post('/api/recruitment/schedule/not_confirm', { schedule_id, participant_recruitment_id })
  },
  present(schedule_id: number | null, participant_recruitment_id: number | null): Promise<void> {
    return post('/api/recruitment/schedule/present', { schedule_id, participant_recruitment_id })
  },
  absent(schedule_id: number | null, participant_recruitment_id: number | null): Promise<void> {
    return post('/api/recruitment/schedule/absent', { schedule_id, participant_recruitment_id })
  },
  absentExcused(schedule_id: number | null, participant_recruitment_id: number | null, reason: string): Promise<void> {
    return post('/api/recruitment/schedule/absent_excused', { schedule_id, participant_recruitment_id, reason })
  },
  include(schedule_id: number | null, participant_recruitment_id: number | null): Promise<void> {
    return post('/api/recruitment/schedule/include', { schedule_id, participant_recruitment_id })
  },
  notInclude(
    schedule_id: number | null,
    participant_recruitment_id: number | null,
    reason: string,
    observation: string
  ): Promise<void> {
    return post('/api/recruitment/schedule/not_include', {
      schedule_id,
      participant_recruitment_id,
      reason,
      observation,
    })
  },
  dropout(schedule_id: number | null, participant_recruitment_id: number | null, reason: string): Promise<void> {
    return post('/api/recruitment/schedule/dropout', { schedule_id, participant_recruitment_id, reason })
  },
  dismiss(schedule_id: number | null, participant_recruitment_id: number | null, reason: string): Promise<void> {
    return post('/api/recruitment/schedule/dismiss', { schedule_id, participant_recruitment_id, reason })
  },
  unschedule(schedule_id: number | null, participant_recruitment_id: number | null, reason: string): Promise<void> {
    return post('/api/recruitment/schedule/unschedule', { schedule_id, participant_recruitment_id, reason })
  },
  unscheduleByCompany(
    schedule_id: number | null,
    participant_recruitment_id: number | null,
    reason: string
  ): Promise<IGroup> {
    return post('/api/recruitment/schedule/unschedule_by_company', { schedule_id, participant_recruitment_id, reason })
  },
  resetStatus(schedule_id: number | null, participant_recruitment_id: number | null): Promise<void> {
    return post('/api/recruitment/schedule/reset_status', { schedule_id, participant_recruitment_id })
  },
  assignScheduleResponsible(schedule_id: number | null, responsible_id: number | null): Promise<IGroup> {
    return post('/api/recruitment/schedule/responsible/add', { schedule_id, responsible_id })
  },
  unassignScheduleResponsible(schedule_id: number | null): Promise<IGroup> {
    return post('/api/recruitment/schedule/responsible/delete', { schedule_id })
  },
}
