import { defineStore } from 'pinia'

interface IToast {
  opened?: boolean
  message: string
  color: string
  timeout?: number
}
const DEFAULT_TIMEOUT: number = 6000

export const useToastStore = defineStore('toast', {
  state: () =>
    ({
      opened: false,
      message: '',
      color: '',
      timeout: DEFAULT_TIMEOUT,
    }) as IToast,
  actions: {
    open(data: IToast): void {
      this.opened = true
      this.message = data.message
      this.color = data.color
      this.timeout = data.timeout || DEFAULT_TIMEOUT
    },
    close(): void {
      this.opened = false
      this.message = ''
      this.color = ''
      this.timeout = DEFAULT_TIMEOUT
    },
  },
})
