export enum EnumStatus {
  WAITING_START = 'waiting_start',
  STARTED = 'started',
  FINISHED = 'finished',
  CANCELED = 'canceled',
}

export enum EnumParticipantScheduleStatus {
  NONE = '',
  CONFIRMED = 'confirmed',
  NOT_CONFIRMED = 'not_confirmed',
  PRESENT = 'present',
  ABSENT = 'absent',
  ABSENT_EXCUSED = 'absent_excused',
  INCLUDED = 'included',
  NOT_INCLUDED = 'not_included',
  DROPOUT = 'dropout',
  DISMISSED = 'dismissed',
  UNSCHEDULED_BEFORE_BEGIN = 'unscheduled_before_begin',
  UNSCHEDULED_CONFIRMATION = 'unscheduled_confirmation',
  UNSCHEDULED_BY_COMPANY = 'unscheduled_by_company',
}

export enum EnumParticipantRecruitmentStatus {
  NONE = 'no_status',
  SCHEDULED = 'scheduled',
  NO_PROFILE = 'no_profile',
  NO_AVAILABILITY = 'no_availability',
  NO_INTEREST = 'no_interest',
  SCHEDULED_RELOCATED = 'scheduled_relocated',
  SCHEDULED_CRITICAL_CLOSE = 'scheduled_critical_close',
  UNSCHEDULED_BEFORE_BEGIN = 'unscheduled_before_begin',
  UNSCHEDULED_CONFIRMATION = 'unscheduled_confirmation',
  UNSCHEDULED_BY_COMPANY = 'unscheduled_by_company',
}

export enum EnumReasonType {
  NONE = '',
  ABSENT = 'absent',
  DROPOUT = 'dropout',
  DISMISSED = 'dismissed',
  UNSCHEDULED = 'unscheduled',
  UNSCHEDULED_BY_COMPANY = 'unscheduled_by_company',
  NO_INTEREST = 'no_interest',
  NOT_INCLUDED = 'not_included',
  ADDITIONAL_VISIT = 'additional_visit',
  FOLLOW_UP = 'follow_up',
}

export enum EnumFileType {
  PROFILE = 'profile',
  DIFFERENTIATED_GIFT = 'differentiated_gift',
  TRANSPORT = 'transport',
}

export enum EnumStudyTypeType {
  DEFAULT = 'default',
  FPS = 'fps',
  PPD = 'ppd',
}
