import en from '@/locales/en.json'
import pt from '@/locales/pt.json'
import { createI18n } from 'vue-i18n'

export default createI18n({
  locale: 'pt',
  fallbackLocale: 'pt',
  messages: {
    pt,
    en,
  },
})
